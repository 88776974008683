.stat-counter{
  padding: 0 1rem;
  position: relative;
  width: fit-content;
  width: -moz-fit-content;

  .value-before, .value-after{
    position: absolute;

    color: rgba(255, 255, 255, .8);
  }

  .value-before{
    top: 0;
    left: 0;
    font-size: 1.05rem;
  }

  .value-after{
    bottom: 8px;
    right: -24px;
    font-size: .8rem;

  }

  .main-value{
    color: var(--white);
    font-size: 1.75rem;
    font-weight: 700;
  }
}