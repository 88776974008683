:root{
  --bg-color: #07080a;
  --bg-color-rgb: rgb(7, 8, 10);
  --color-main-light: #02a5ce;
  --color-main-light-rgb: rgb(2, 165, 206);
  --color-main-normal: #3f9aa8;
  --color-main-normal-rgb: rgb(63, 154, 168);
  --color-main-dark: #008ba0;
  --color-main-dark-rgb: rgb(0, 139, 160);
  --black: #000000;
  --white: #ffffff;
  --color-warning: #ffc126;
  --color-error: #ff0000;
  --color-success: #07c68d;
  --color-bg: rgb(0, 13, 15);
  --blue-alternate: #003763;
  --blue-alternate-rgb: rgb(0, 55, 99);
}



body{
  font-size: 10px !important;
  background: var(--color-bg);
}

.flexbox{
  display: flex;
}

.align-center{
  align-items: center;
}

.justify-center{
  justify-content: center;
}

.space-between{
  justify-content: space-between;
}

.column{
  flex-direction: column;
}

.centered{
  margin-left: auto;
  margin-right: auto;
}

.relative-item{
  position: relative;
}

.absolute-item{
  position: absolute;
}

.fixed{
  position: fixed;
}

.top-left{
  top: 0;
  left: 0;
}

.top-right{
  top: 0;
  right: 0;
}

.bottom-left{
  bottom: 0;
  left: 0;
}

.bottom-right{
  bottom: 0;
  right: 0;
}

.absolute-center-vertical{
  top: 50%;
  transform: translateY(-50%);
}

.absolute-center-horizontal{
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-container{
  .button:not(.button-transparent){
    svg{
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
    }
  }

  svg{
    width: 13px;
    height: 13px;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    right: 16px;
  }
}

.button{
  padding: 12px 22px;
  font-size: .8rem;
  font-weight: 700;
  color: var(--white);
  font-family: inherit;
  position: relative;
  opacity: .9;
  border-radius: 8px;
  transition: ease-in-out .3s;

  &:hover{
    transition: opacity .9s;
    opacity: .7;
    cursor: pointer;
  }

  &:disabled{
    opacity: .4;
  }
}

.button-danger{
  border: 1px solid rgba(255, 0, 0, .5);
  color: rgba(255, 0, 0, .8);
  background: transparent;

  &:hover{
    background: rgba(255, 0, 0, .5);
    color: var(--white);
  }
}

.button-transparent{
  background: transparent;

}

.blue-alternate{
  background: var(--blue-alternate);
  border: 1px solid var(--blue-alternate);
}

.light-transparent{
  border: 1px solid var(--color-main-light);
  color: var(--color-main-light);

  &:hover{
    background: var(--color-main-light);
    color: var(--white);
  }
}

.main-transparent{
  border: 1px solid var(--color-main-normal);
  color: var(--color-main-normal);

  &:hover{
    background: var(--color-main-normal);
    color: var(--white);
  }
}

.blue-transparent{
  border: 1px solid var(--color-main-dark);
  color: var(--color-main-dark);

  &:hover{
    background: var(--color-main-dark);
    color: var(--white);
  }
}

.dark-transparent{
  border: 1px solid var(--black);
  color: var(--black);

  &:hover{
    background: var(--black);
    color: var(--white);
  }
}

.white-transparent{
  border: 1px solid var(--white);
  color: var(--white);

  &:hover{
    transform: scale(1.05);
  }
}

.button-light{
  background: var(--color-main-light);
  border: 1px solid var(--color-main-light);
  color: var(--white);
}

.button-regular{
  background: var(--color-main-normal);
  border: 1px solid var(--color-main-normal);
  box-shadow: 0 4px 8px  rgba(7, 89, 155, .4);
}

.button-main{
  background: var(--color-main-dark);
  border: 1px solid var(--color-main-dark);
}

.button-dark{
  background: var(--black);
  border: 1px solid var(--black);
}

.input-control{
  label{
    font-size: .8rem;
    font-weight: 700;

  }

  input, textarea{
    padding: 9px 27px;
    border: 1px solid rgba(2, 165, 206, .09);
    font-size: .9rem;
    width: 100%;
    border-radius: 8px;
    color: var(--color-main-dark);
    background: rgba(7, 89, 155, 0.12);
    transition: ease-in-out .2s;
    margin-top: 12px;

    &.alternate{
      border-right-style: none;
      border-top-style: none;
      border-left-style: none;
      border-bottom: 1px solid rgba(2, 165, 206, .6);
      background: transparent;
      color: var(--white);
      border-radius: 0;

      &:focus{
        border-right-style: none;
        border-top-style: none;
        border-left-style: none;
        border-bottom: 1px solid var(--color-main-light);
      }
    }

    &.input-error{
      border: 1px solid rgba(255, 0, 0, .2);
      animation: vibrate .6s;
      background: rgba(255, 0, 0, .13) !important;
    }

    &.input-success{
      border: 1px solid rgba(7, 198, 141, .2);
      background: rgba(7, 198, 141, .13);
    }

    &::placeholder{
      color: rgba(255, 255, 255, .45);
    }

    &:focus{
      border: 1px solid var(--color-main-light);
      transition: .3s ease;
      outline: 0;
    }
}

  textarea{
    height: 180px;
    font-family: 'Amiko', sans-serif;
  }

  .input-error{
    color: rgba(255, 0, 0, .6);
    animation: vibrate .6s;
  }
}

.half-width{
  width: 50%;
}

.full-width{
  width: 100%;
}

.full-height{
  height: 100vh;
}

.grid{
  display: grid;
  justify-content: space-between;
  grid-gap: 25px 0;
}

.double-grid{
  grid-template-columns: repeat(2, 1fr);
}

.triple-grid{
  grid-template-columns: repeat(3, 1fr);
}

.quadruple-grid{
  grid-template-columns: repeat(4, 1fr);
}

.rounded{
  border-radius: 50%;
}

.link-item{
  font-size: .75rem;
  color: var(--color-main-dark);
  text-decoration: underline;
}

.text-center{
  text-align: center;
}

.margin-top-xs{
  margin-top: .6rem;
}

.margin-left-xs{
  margin-left: .6rem;
}

.font-sm{
  font-size: .75rem;
}

.font-md{
  font-size: 1.1rem;
}

.container{
  padding: 2.2rem 1.5rem;
  border-radius: 16px;

  @media screen and (min-width: 650px) and (max-width: 1199px) {
    width: calc(85% - 3.5rem);
  }

  @media screen and (max-width: 649px) {
    width: calc(100% - 1.5rem);
  }
}
.container-sm{
  @media screen and (min-width: 1200px) {
    width: calc(42.5% - 10rem);
  }
}

.container-md{
  @media screen and (min-width: 1200px) {
    width: calc(65% - 10rem);
  }

  @media screen and (min-width: 750px) and (max-width: 1200px) {
    width: calc(90% - 3rem);
  }

  @media screen and (max-width: 749px) {
    width: calc(100% - 1.5rem);
  }
}

.container-lg{
  @media screen and (min-width: 1200px) {
    width: calc(85% - 10rem);
  }
}

.hidden{
  display: none !important;
}


.page-container{
  .main-content{
    //transition: ease-in-out .4s;

    &.faded{
      opacity: .3;
      position: fixed;
      left: 250px;
      padding-top: 0;
    }

    &.unauthenticated, {
      padding: 40px 0;
    }

    &.authenticated{
      padding: 60px 0;
    }

    @media screen and (min-width: 990px) {
      &.authenticated{
        width: calc(100% - 275px);
      }

      &.unauthenticated{
        width: 100%;
      }
    }

    @media screen and (max-width: 989px) {
      width: 100%;
      margin-right: 0;
    }
    margin-left: auto;
    margin-right: 1.5rem;
    position: relative;

    .content-container{
      min-height: 100vh;

      @media screen and (min-width: 421px){
        width: calc(95% - 2rem);
      }

      @media screen and (max-width: 420px) {
        width: 100%;
      }
      margin: 0 auto;
    }
  }
}

a.active, .color-gradient{
  background: linear-gradient(to right, rgba(255, 255, 255, .75), rgba(0, 139, 160, .8));
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.modal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, .75);
  width: 100%;
  height: 100%;

  .modal-content{
    background: var(--black);
    border-radius: 16px;
    padding: 1.5rem 1.1rem;
    position: relative;
    color: var(--white);
    margin: 100px auto;
    animation: slideDown .5s;
    box-shadow: 2px 2px 2px 6px rgba(0, 139, 160, .25);
    max-width: 850px;

    .close-button{
      position: absolute;
      top: -17.5px;
      right: -8px;
      width: 35px;
      height: 35px;
      background: var(--color-bg);
      color: var(--white);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: ease-in-out .3s;

      &:hover{
        cursor: pointer;
        background: var(--color-main-normal);
      }

      .close-icon{
        width: 18px;
        height: 18px;
      }
    }
  }

  @keyframes slideDown {
    from{ margin-top: 0 }
    to{ margin-top: 100px }
  }
}


.page-container{
  .react-datepicker, .react-datepicker__header{
    background: var(--color-bg);
    color: var(--color-main-dark);
    border: 1px solid rgba(2, 165, 206, .4);
  }

  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    color: rgba(255, 255, 255, .6);
  }

  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color: var(--color-main-normal);
  }

  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{
    border-top-color: rgba(2, 165, 206, .4);
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    border-bottom-color:rgba(2, 165, 206, .4);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range{
    background: var(--color-main-normal);
    border-radius: 50%;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover{
    background: var(--color-main-dark);
    opacity: .8;
    border-radius: 50%;
  }
}

.tooltip{
  position: absolute;
  max-width:400px;
  border-radius: 8px;
  font-size: .7rem;
  text-align: center;
  color: var(--white);
  left: 50%;
  transform: translateX(-50%);
  animation: fadeIntoView .3s;

  &.regular{
    background:  rgba(0, 55, 99, .45);

  }

  &.error{
    background: rgba(255, 0, 0, .5);
  }

  &.above{
    top: -20px;

    &.error{
      .content{
        &::before{
          border-top: 5px solid rgba(255, 0, 0, .5);
          top: 100%;
        }
      }
    }

    &.regular{
      .content{
        &::before{
          border-top: 5px solid  rgba(0, 55, 99, .45);
          top: 100%;
        }
      }
    }
  }

  &.below{
    top: 105%;

    .content{
      &::before{
        top: -5px;
      }
    }

    &.error{
      .content{
        &::before{
          border-bottom: 5px solid rgba(255, 0, 0, .3);
        }
      }
    }

    &.regular{
      .content{
        &::before{
          border-bottom: 5px solid rgba(0, 55, 99, .45);
        }
      }
    }
  }

  .content{
    width: 100%;
    padding: .4rem .6rem;
    position: relative;

    &::before{
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      left: 50%;
      transform: translateX(-50%);
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }

  @keyframes fadeIntoView {
    from{opacity: 0;}
    to{opacity: 1}
  }
}


.error-section{
  width: 100%;
  padding: .8rem 1.35rem;
  background: rgba(255, 0, 0, .3);
  font-size: .75rem;
  color: rgba(255, 255, 255, .85);
  border-radius: 10px;
  margin: 1.5rem 0 0;

}

@keyframes vibrate {
  0%{ transform: translateX(0px);}
  25%{ transform: translateX(-7px);}
  50%{ transform: translateX(7px); }
  75%{ transform: translateX(-7px); }
  100%{ transform: translateX(0px); }
}

@-webkit-keyframes vibrate {
  0%{ transform: translateX(0px);}
  25%{ transform: translateX(-7px);}
  50%{ transform: translateX(7px); }
  75%{ transform: translateX(-7px); }
  100%{ transform: translateX(0px); }
}

@-moz-keyframes vibrate {
  0%{ transform: translateX(0px);}
  25%{ transform: translateX(-7px);}
  50%{ transform: translateX(7px); }
  75%{ transform: translateX(-7px); }
  100%{ transform: translateX(0px); }
}

@-o-keyframes vibrate {
  0%{ transform: translateX(0px);}
  25%{ transform: translateX(-7px);}
  50%{ transform: translateX(7px); }
  75%{ transform: translateX(-7px); }
  100%{ transform: translateX(0px); }
}

