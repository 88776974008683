.suggestion{
  position: relative;

  .input-control{
    position: relative;

    input{
      padding: 8px 30px;
      font-size: .75rem;
      margin-top: 0;
    }

    .icon{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.search-icon{
        width: 14px;
        height: 14px;
        color: var(--white);
        left: 10px;
      }

      &.spinner-icon{
        right: 10px;
      }
    }
  }

  .suggestion-list{
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid rgba(2, 165, 206, .15);
    background: var(--black);
    min-width: 200px;
    z-index: 99;
    list-style: none;
    padding-left: 0;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar{
      width: 2px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb{
      background: var(--color-main-normal);
      border-radius: 4px;
    }

    .data-item{
      padding: 8px 20px;
      line-height: 1.4rem;
      color: rgba(255, 255, 255, .8);
      font-size: .75rem;
      transition: ease .2s;

      &:hover{
        background: var(--color-main-dark);
        cursor: pointer;
      }
    }
  }

  .no-results{
    font-size: .75rem;
    color: var(--white);
    text-align: center;
    padding: 10px 0;
    border: 1px solid rgba(2, 165, 206, .15);
    background: var(--black);
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 200px;
  }


}