
.side-panel{
  background: rgb(0, 0, 0);
  border-right: 1px solid rgba(255, 255, 255, .15);
  height: 100vh;
  overflow-y: auto;
  white-space: nowrap;
  padding: 5rem 0;
  transition: ease-in-out .3s;
  overflow-x: hidden;



  @media screen and (min-width: 990px) {
    width: 250px;
    position: fixed;
    top: 35px;
    left: 0;
  }

  @media screen and (max-width: 989px) {
    width: 0;
  }

  &.mobile-closed{
    @media screen and (max-width: 989px) {
      width: 0;
    }
  }

  &.mobile-open{
    @media screen and (max-width: 989px) {
      width: 250px;
    }
  }
  .sidebar-items{
    margin: 1.2rem auto;
    width: 200px;
   /* width: calc(100% - 3.5rem);*/

    .link-section:not(:last-child){
      margin-bottom: 1rem;
    }

    .sign-out-section{

      @media screen and (min-width: 990px) {
        display: none;
      }
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      a{
        color: var(--white);
        font-size: .85rem;
        margin-left: .6rem;
        text-decoration: none;
      }
     svg{
       width: 17px;
       height: 17px;
       filter: invert(98%) sepia(0%) saturate(842%) hue-rotate(128deg) brightness(116%) contrast(100%);
       transform: rotate(180deg);
     }
    }

    .item-main-heading{
      font-size: .85rem;
      padding: 1rem 0;
      color: var(--white);
      font-weight: 700;
      text-transform: uppercase;
    }

    .linklist{
      padding-left: .5rem;

      li{
        line-height: 2.5rem;
      }

      a{
        text-decoration: none;
        color: var(--white);
        display: flex;
        align-items: center;

        svg{
          width: 20px;
          height: 20px;
          margin-right: 8px;
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
        }
      }

      &.childlist{
        li{
          &.childlist-item{
            list-style-type: none;
          }
          a{
            font-size: .85rem;

            &:hover{
              opacity: .7;
              color: var(--color-main-light);
              transition: .2s ease;
            }
          }
        }
      }

      &.grandChildList{
        padding-left: 1.5rem;

        li{
          list-style-image: url("../../assets/icons/caret-right.svg");
          position: relative;


         /* &::before{
            content: ;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
            width: 16px;
            height: 16px;
          }*/
          a{
            font-size: .8rem;

            &:hover{
              transition: .2s ease;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
