.main-panel{
  color: var(--white);
  padding: 2rem 1.2rem;
  border-radius: 16px;

  header{
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 1.35rem;
    color: rgba(255, 255, 255, .8);


    @media screen and (min-width: 750px) and (max-width: 1399px) {
      text-align: center;
    }
  }
  svg{
    width: 50px;
    height: 50px;
    transform: scaleX(-1);
    filter: invert(64%) sepia(35%) saturate(7054%) hue-rotate(359deg) brightness(99%) contrast(110%);
  }

  h3{
    font-size: 1.3rem;
    margin-top: 1.1rem;

    span{
      margin-left: .3rem;
      color: var(--color-main-dark);
    }
  }

  .stat-content{
    @media screen and (min-width: 1340px) {
      justify-content: space-between;
    }

    @media screen and  (max-width: 1399px) {
      flex-direction: column;
      align-items: center;

      .stat-data{
        margin-bottom: 3rem;
      }
    }

    @media screen and (min-width: 990px) and (max-width: 1399px) {
      .stat-count-items{
        width: calc(60% - 1.5rem);
      }
    }

    @media screen and (min-width: 550px) and (max-width: 989px) {
      .stat-count-items{
        width: calc(85% - 1.5rem);
      }
    }

    @media screen and (max-width: 549px) {
      .stat-count-items{
        width: calc(95% - 1.5rem);
      }
    }

    @media screen and (max-width: 800px) {
      .stat-data{
        flex-wrap: wrap;

        .stat-item{
          width: 50%;
          margin-bottom: 2rem;

          &:not(:last-child) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .stat-data{
    display: flex;
    justify-content: center;

    .stat-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      .stat-text{
        text-align: center;
        margin-top: .7rem;
        font-size: .85rem;
        font-weight: 700;
      }

      &:not(:last-child) {
        margin-right: 3rem;
      }
    }
  }

  .stat-count-items{
    display: flex;
    flex-wrap: wrap;
    width: calc(35% - 1.5rem);
    padding: 1.5rem 1rem;
    border-radius: 20px;
    background: var(--black);
    box-shadow: 6px 6px 1px 2px rgba(2, 165, 206, .15),
              -6px -6px 1px 2px rgba(2, 165, 206, .3);

    @media screen and (min-width: 550px) {
      justify-content: space-between;

      .data-content{
        width: calc(50% - 1.5rem);
      }
    }
    @media screen and (max-width: 549px) {
      flex-direction: column;
      align-items: center;

      .data-content{
        width: calc(85% - 1.5rem);
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
    .data-content{

      .stat-counter{
        margin: 0 auto;
      }

      .stat-label{
        font-size: .7rem;
        color: var(--white);
        margin-top: .45rem;
        text-align: center;
        width: 100%;
      }
    }


  }

}

.recent-activity{
  margin: 3.2rem 0;

  .no-activity-data{
    font-size: .9rem;
    color: var(--white);
    opacity: .8;
  }

  .greeting{
    color:rgba(255, 255, 255, .9);
    font-size: .95rem;
    margin-bottom: .75rem;
    font-weight: 600;
  }

  .main-heading{
    font-size: 1rem;
    line-height: 2rem;
    color: var(--color-main-dark);
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  ul{
    list-style: none;

    &.activity-content{

      @media screen and (min-width: 481px) {
        padding-left: 2rem;
      }

      @media screen and (max-width: 480px) {
        padding-left: 1rem;
      }
      padding-bottom: 2rem;
    }

    &.timeline-content{
      padding-left: 1.4rem;


    }

    .activity-date{
      font-size: .95rem;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 2.2rem;
      color: var(--white);
    }

    .activity-data{
      display: flex;

      .activity-pointer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .line-item{
          height: 40%;
          width: 2px;
          background: rgba(0, 55, 99, .5);
        }

        .circle-item{
          width: 8px;
          height: 8px;
          border-radius: 50%;

          &.task_created-item{
            background: var(--color-main-light);
          }

          &.project_updated-item,
          &.task_updated-item{
            background: #ff7b00;
          }

          &.task_deleted-item,
          &.project_deleted-item{
            background: #ff0000;
          }

          &.project_created-item{
            background: var(--color-success);
          }
        }
      }
      .activity-content{
        padding: 1.7rem 1rem;

        @media screen and (min-width: 990px) {
          width: calc(75% - 3rem);
        }

        @media screen and (max-width: 989px) {
          width: 100%;
        }

        h4{
          font-size: .9rem;
          line-height: 2rem;
          color: var(--color-main-dark);
        }

       span{
         font-size: .75rem;
         color: var(--color-main-normal);
       }

        .task-desc{
          font-size: .8rem;
          line-height: 2.4rem;
          color: var(--white);
        }

        .task-desc,
        .task-assignment{
          max-width: calc(100% - 5rem);
        }

        .task-assignment{
          font-size: .75rem;
          line-height: 2rem;
          font-style: italic;
          color: var(--white);
        }
      }
    }
  }
}
