.stat-circle{
  position: relative;
  //background: conic-gradient( rgba(255, 255, 255, .3) 50%, #ffffff 50%);
  width: 85px;
  height: 85px;
  border-radius: 50%;
  transition: ease .3s;
  /*
      .rounder{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;

        clip-path: inset(0 0 0 50%);
        background: purple;

        .bar{
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: var(--white);
          clip-path: inset(0 50% 0 0);
          animation: fillRotate 2s;
          transform: rotate(180deg);

          @keyframes fillRotate {
              0%{
                transform: rotate(0deg);
              }

            to{
              transform: rotate(180deg);
            }
          }
        }
      }*/

  .circle-inner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background: var(--black);
  }
}