.error_container{
  display: flex;
  align-items: center;
  color: var(--white);
  width: 100%;
  height: 100vh;
  justify-content: center;

  .icon{
    color: var(--color-main-light);
    margin-right: .5rem;
    width: 28px;
    height: 28px;
    animation: jump 3s linear infinite;
    @keyframes jump {
      0%{
        transform: translateY(0px);
        opacity: .3;
      }

      50%{
        transform: translateY(-10px);
      }

      75%{
        opacity: .6;
      }

      25%, 100%{
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }

  .text{
    font-size: .85rem;
    font-weight: 700;
  }

  .refresh{
    width: 20px;
    height: 20px;
    margin-left: .8rem;
  }
}
