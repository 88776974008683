.contact-image{
  width: 30px;
  height: 30px;
  background: rgba(63, 154, 168, .5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;

  .contact-icon{
    width: 12px;
    height: 12px;
    color: var(--white);
  }
}
