.banner{
  width: 100%;
  overflow-x: hidden;
  margin: 1.5rem 0;
}
.content{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  transition: all .8s;
}

.item{
  width: 100%;
  margin: 0 auto;
  flex: 0 0 auto;
  text-align: center;
  font-size: .85rem;
  background: linear-gradient(to right, rgba(255, 255, 255, .75), rgba(0, 139, 160, .8));
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}