.page-container{
  display: flex;
  width: 100%;
  min-height: 100vh;

  &.overflow-hidden{
    overflow: hidden;
    height: 100vh;
  }

  .login-section{
    min-height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar{
      display: none;
    }
    .content-items{
      @media screen and (min-width: 1200px) {
        width: calc(35% - 5rem);
      }

      @media screen and (min-width: 850px) and (max-width: 1200px) {
        width: calc(90% - 5rem);
      }

      @media screen and (min-width: 550px) and (max-width: 849px) {
        width: calc(80% - 5rem);
      }

      @media screen and (max-width: 549px) {
        width: calc(95% - 1.5rem);
      }

      .form-container{
        padding: 1.5rem;
        border-radius: 20px;

        header{
          font-size: 1.25rem;
          color: var(--color-main-dark);
          font-weight: 600;
          margin-bottom: 2rem;
        }

        input{
          width: 100%;
          margin-top: 0;
        }

        .input-control:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        .input-control{
          .eye-icon{
            right: 16px;
            width: 16px;
            height: 16px;
            opacity: .7;

            svg{
              filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(104%) contrast(102%);
            }

            &:hover{
              cursor: pointer;
            }
          }
        }

        .button-container{
          margin-top: 2.4rem;

          button{
            width: 100%;
          }
        }
      }
    }


    @media screen and (max-width: 850px) {
      width: 100%;
    }

    .remember-user{
        color: var(--white);
    }

  }

  .tooltip{
    &.above{
      top: -40px;
    }
  }
}
