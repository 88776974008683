.project-header{
  margin: 2rem 0;

  @media screen and (max-width: 549px) {
    flex-direction: column;

    h3{
      text-align: center;
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (min-width: 550px) {
    align-items: center;
    justify-content: space-between;
  }
  h3{
    color: var(--color-main-dark);
    font-size: 1.1rem;
  }

  a{
    color: var(--color-main-normal);
    text-decoration: none;
    font-size: .85rem;
    font-weight: 600;
    svg{
      width: 20px;
      height: 20px;
      filter: brightness(0) saturate(100%) invert(16%) sepia(28%) saturate(4568%) hue-rotate(186deg) brightness(91%) contrast(104%);
      margin-right: .7rem;
    }

    &:hover{
      opacity: .7;
      transition: .3s ease;
    }
  }
}

.project-listing{
  .project-item{
    background: rgba(0, 0, 0, .35);
    padding: 1.2rem 1.5rem;
    border-radius: 20px;
    border: 1px solid rgba(75, 162, 234, .09);
    box-shadow: 0 2px 2px 2px rgba(75, 162, 234, .09);

    &:not(:last-child) {
      margin-bottom: 2rem
    }

    .project-content{
      max-width: calc(80% - 5rem);

      .project-link{
        text-decoration: none;
        font-size: 1rem;
        font-weight: 700;
        line-height: 2.4rem;
        color: var(--color-main-dark);

        &:hover{
          text-decoration: underline;
        }
      }

      .content-data{
        margin-bottom: 1.5rem;
        color: rgba(255, 255, 255, .8);
        span{
          font-size: .75rem;
          &:not(:last-child) {
            margin-right: .6rem;
          }
        }
      }

      .lower-section{
        .edit-link{
          font-size: .75rem;
          color: var(--color-main-normal);
          text-decoration: none;
          display: flex;
          align-items: center;

          span{
            margin-left: .4rem;
          }
        }

        .project-status{
         // margin-left: 1.8rem;
          display: flex;
          align-items: center;

          .circle{
            margin-right: .4rem;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            opacity: .75;

            &.disabled{
              background: var(--color-error);
            }

            &.active{
              background: var(--color-success);
            }
          }

          .status-text{
            font-size: .75rem;

            &.disabled{
              color: var(--color-error);
            }

            &.active{
              color: var(--color-success);
            }
          }
        }
      }
    }

    .project-icon{
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: rgba(7, 89, 155, .3);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;

      &.disabled{
        opacity: .25;
      }

      svg{
        width: 24px;
        height: 24px;
        filter: invert(16%) sepia(28%) saturate(4568%) hue-rotate(186deg) brightness(91%) contrast(104%);
      }

    }

    .taskboard-link{
      color: var(--color-main-normal);
      margin-left: auto;
      text-decoration: none;

      &:hover{
        opacity: .7;
        transition: .2s ease;
      }

      svg{
        width: 32px;
        height: 32px;
        margin-bottom: .45rem;
      }

      div{
        font-size: .7rem;
      }
    }
  }

}

.no-projects{
  color: var(--white);
  padding: 2.5rem 0;
  font-size: .85rem;
}
