.navigation{
  background: rgb(0, 0, 0);
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(75, 162, 234, .15);
  z-index: 999;


  @media screen and (max-width: 989px) {
      padding: 0 1.2rem;
  }

  @media screen and (min-width: 990px) {
      padding: 0 2.4rem;
  }
}



.top-panel{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;

  .panel-item{
    width: 30%;

    .menu-icon{
      color: var(--white);
      width: 18px;
      height: 18px;

      @media screen and (min-width: 990px) {
        display: none;
      }
    }

    .sign-out-panel{
      overflow-x: hidden;

      @media screen and (max-width: 990px) {
        display: none;
      }

      .sign-out-text{
        font-size: .95rem;
        margin-left: .6rem;
        color: var(--white);
        font-weight: 700;
        width: 200px;
        display: none;
      }

      @media screen and (min-width: 990px) {
        &:hover{
          width: 100px;
          transition: .3s ease;
          cursor: pointer;
          .sign-out-text{
            display: block;
          }
        }
      }
      svg{
        width: 18px;
        height: 18px;
        transform: rotate(180deg);filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(39deg) brightness(102%) contrast(101%);
    }
    }

    &.logo-item{
      display: flex;
      justify-content: center;
      a{
        img{
          display: block;
          width: 50px;
          height: 50px;

          &:hover{
            transform: rotate(180deg);
            transition: .3s ease;
          }
        }
      }

    }

    &.user-section{
      .user-action{
        width: fit-content;
        width: -moz-fit-content;
        margin-left: auto;
        display: flex;
        align-items: center;

        .user-name{
          color:var(--white);
          font-size: .7rem;

          .large-display{
            @media screen and (max-width: 990px) {
              display: none;
            }
          }

          .mobile-display{
            @media screen and (min-width: 990px) {
              display: none;
            }
          }
        }

        .user-pfp{
          img{
            @media screen and (min-width: 990px) {
              margin-right: .7rem;
              width: 30px;
              height: 30px;
            }

            @media screen and (max-width: 989px) {
              width: 22px;
              height: 22px;
            }
          }

          svg{
            width: 14px;
            height: 14px;
          }

          .space-image{
            .contact-image{
              @media screen and (max-width: 990px) {
                margin-right: 0;
              }
            }
          }
        }
      }


    }
  }
}
