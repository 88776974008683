.page-content{
  width: calc(95% - 1.5rem);
  margin: 0 auto;
  color: var(--white);

  .header-section{
    padding: 3.5rem 0 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, .25);

    .project-widget{
     // width: calc(45% - 1.5rem);
      margin: 0 auto .8rem;

      .project-icon{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: linear-gradient(to bottom right, rgba(2, 165, 206, .32),rgb(63, 154, 168), rgba(0, 139, 160, .22));
        color: var(--white);
        font-size: 30px;
        //margin: 0 auto;
      }

      header{
        font-size: 1.15rem;
        margin-top: .5rem;
        font-weight: 700;
        span{
          padding-top: .25rem;
          padding-right: 1rem;
        }


        .icon{
          width: 15px;
          height: 15px;
        }
      }

      form{
        width: calc(35% - 1.5rem);

        .input-control{
          width: 85%;
          position: relative;
        }

        button{
          padding: 4px 14px;
          font-size: .75rem;
          border-radius: 15px;
          margin-left: .7rem;
          font-weight: 500;
          color: var(--white);
        }
      }

    }

    .header-action-panel{
      .project-status{
        header{
          font-size: .95rem;
          font-weight: 700;
          margin: 1rem 0 .6rem;
        }
        .status-item{
          font-size: .75rem;

          &:first-child{
            opacity: .45;
            margin-right: .6rem;
          }

          &:last-child{
            color: var(--color-success);
            margin-left: .6rem;
          }
        }


      }

      .taskboard-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--white);
        text-decoration: none;

        .icon{
          width: 24px;
          height: 24px;
          margin-bottom: .6rem;
        }

        span{
          font-size: .75rem;
        }
      }
    }
  }

  .container-form{
    padding: 3.5rem 0;

    .data-item{
      &.full-row{
        grid-column: 1 / span 2;
      }
      .item-label{
        font-size: .85rem;
        line-height: 2.4rem;
        color: var(--color-main-dark);
        margin-bottom: .5rem;

        .icon{
          color: var(--white);
          margin-left: 12px;
        }
      }

      .item-data{
        @media screen and (min-width: 550px) {
          padding-right: 2.2rem;
        }

        @media screen and (max-width: 549px) {
          padding-right: 1.2rem;
        }
        color: rgba(255, 255, 255, .85);
        .data-value{
          span{
            font-size: .95rem;
            line-height: 1.8rem;
          }
        }


        form{

          .input-control{
            position: relative;

            input{
              margin-top: 0;
            }
          }
         input[type="submit"] {
            margin-left: auto;
           margin-top: .5rem;
            border-radius: 16px;
            padding: 6px 18px;
          }
        }
      }


      .icon{
        width: 13px;
        height: 13px;

        &:hover{
          transform: scale(1.08);
          opacity: .7;
          transition: .3s ease;
          cursor: pointer;
        }
      }
    }
  }
  .bottom-section {
    border-top: 1px solid rgba(255, 255, 255, .25);
    padding: 1.5rem 0;
  }


}

.action-bar{
  position: fixed;
  right: 0;
  background: var(--black);
  height: 65px;
  align-items: center;
  display: flex;
  justify-content: space-between;


  @media screen and (min-width: 990px) {
    width: calc(100% - 250px);
    padding: 0 3.5rem;
  }

  @media screen and (max-width: 989px){
    width: 100%;
    padding: 0 1.5rem;
  }

  top: 70px;
  box-shadow: 0 2px 4px 2px rgba(2, 165, 206, .09);
  z-index: 1;
  animation: slid .6s;
  transition: ease-in-out .6s;

  @keyframes slid{
    from{
      transform: translateY(-70px);
      z-index: -1;
      opacity: .1;
    }

    to{
      transform: translateY(0px);
      z-index: 1;
      opacity: 1;
    }
  }

  .button{
    padding: 8px 22px;
    &:first-child{
      margin-right: .75rem;
    }
  }

  .feedback-text{
    color: var(--white);
    font-size: .9rem;
    line-height: 1.5rem;
  }

  .button-holder{
    width: fit-content;
    width: -moz-fit-content;
    margin-left: auto;
  }
}


.modal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 650px) {
    padding: 2.5rem 0;
    overflow-y: auto;

    .modal-content{
      margin-top: 3.5rem;
    }
  }
  .delete-modal{
    text-align: center;
    header{
      font-size: 1rem;

      margin: 1.2rem 0;
    }

    .button-items{
      margin: 2rem 0;

      .button{
        &:first-child{
          margin-right: .6rem;
        }
      }

      .button-danger{
        width: 88px;
        height: 44px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
