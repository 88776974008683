.app-name{
  margin: 2rem auto;
  text-align: center;
  width: calc(75% - 2rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .banner-item{
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.4rem;
    margin-right: .5rem;
    display: inline-block;
    }
}