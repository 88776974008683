$itemColor: rgba(255, 255, 255, .65);
.dropdown-container{
  position: relative;
  background: black;

  .select{
    padding: 8px 15px;
    border: 1px solid rgba(2, 165, 206, .3);
    border-radius: 8px;
    color: $itemColor;
    font-size: .7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 160px;
    transition: ease-in-out .3s;

    &:hover{
      border: 1px solid var(--color-main-light);
      cursor: pointer;
    }

    .toggle{
      width: 14px;
      height: 14px;
      color: $itemColor;
      margin-right: -5px;
      transition: all .3s;

      &.open{
        transform: rotate(180deg);
      }

      &.close{
        transform: rotate(0deg);
      }
    }
  }

  .dropdown{
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid rgba(2, 165, 206, .15);
    background: var(--black);
    min-width: 200px;
    z-index: 99999;

    .search-holder{
      padding: .6rem .5rem;
      border-bottom: 1px solid rgba(2, 165, 206, .4);

      .input-control{
        position: relative;
        input{
          padding: 8px 36px;
          font-size: .75rem;
        }

        .search-icon{
          width: 16.5px;
          height: 16.5px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 14px;
        }
      }
    }

   .dropdown-list{
     list-style: none;
     padding-left: 0;
     max-height: 200px;
     overflow: auto;
     background: var(--black);

     &::-webkit-scrollbar{
       width: 2px;
       background: transparent;
     }

     &::-webkit-scrollbar-thumb{
       background: var(--color-main-normal);
       border-radius: 4px;
     }

     .data-item{
       padding: 8px 20px;
       line-height: 1.4rem;
       color: $itemColor;
       font-size: .75rem;
       transition: ease .2s;

       &:hover{
         background: var(--color-main-dark);
         cursor: pointer;
       }
     }
   }

    .no-results{
      font-size: .75rem;
      color: var(--white);
      text-align: center;
      padding: 10px 0;
    }
  }
}