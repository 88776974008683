.checkbox-container{
  input{
    display: none;

    & + label{
      width: 16px;
      height: 16px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--color-main-dark);

      &:hover{
        opacity: .8;
        transition: .2s ease;
        cursor: pointer;
      }

      svg{
        width: 10px;
        height: 10px;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(104%) contrast(102%);
      }
    }
  }
}