.register-container{
  min-height: 100vh;
  display: flex;
  align-items: center;

  .form-container{
    background: transparent;
    box-shadow: 2px 4px 4px 2px rgba(2, 165, 206, .05);

    .header-column{
      margin-bottom: 2rem;
    }

    .stage-label, header{
      color: var(--color-main-dark);
      font-weight: 600;
    }


    .stage-label{
      font-size: 1.25rem;
      margin-bottom: 1.2rem;
    }

    .stage-desc{
      font-size: .85rem;
      color: rgba(255, 255, 255, .8);
      line-height: 1.3rem;
    }

    header{
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    .main-form{
      flex-wrap: wrap;
      justify-content: space-between;

      .input-control{
        width: 100%;
        margin-bottom: 1.5rem;

        &.half-item{
          width: calc(50% - 1.5rem);
        }

        &:hover{

        }
      }

      input{
        width: 100%;
      }


      .resend-link{
        font-size: .75rem;
        color: var(--color-main-light);
        margin: 1.2rem auto;
        width: fit-content;
        width: -moz-fit-content;
        cursor: pointer;
        transition: all .2s;

        &:hover{
          opacity: .7;
        }
        span{
          margin-right: .6rem;
        }
      }
    }

    .button-container{
      &:not(.verify-stage) {
        margin-top: 2.5rem;
      }
    }

    .stages{
      display: flex;
      margin-bottom: 2rem;

      .splitter-item{
        display: flex;
        align-items: center;
        width: calc((100% - 3rem) / 2);

        .splitter{
          flex-grow: 100;
          height: 1px;
          background: rgba(2, 165, 206, .45);
        }
      }

      .stage-item{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background: transparent;
        color: var(--color-main-normal);
        border: 1px solid rgba(2, 165, 206, .45);



        .stage-icon{
          width: 22px;
          height: 22px;
          filter: invert(62%) sepia(36%) saturate(7233%) hue-rotate(158deg) brightness(93%) contrast(98%);;
        }

        &.active-stage{
          background: rgba(2, 165, 206, .9);
          animation: view 1s;

          &:hover{
            transform: scale(1.05);
            cursor: pointer;
            transition: .3s ease;
          }

          .stage-icon{
           filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }

          @keyframes view {
            0%{
              background: transparent;
            }

            50%{
              background: rgba(2, 165, 206, .25);
              transform: scale(1.05);
            }

            100%{
              background: rgba(2, 165, 206, .6);
              transform: scale(1);
            }
          }
        }
      }
    }

    .success-stage{
      margin: 2rem 0;
      .animate-container{
        width: 70px;
        height: 70px;
        .success-animate{
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(7, 198, 141, .7);
          animation: rotateItem 1s;
          //border: 2px solid rgba(7, 198, 141, .9);
          box-shadow: 0 4px 8px rgba(7, 198, 141, .35), 2px 6px 12px rgba(7, 198, 141, .4);



          @keyframes rotateItem {
            0%{
              transform: rotate(0deg);
            }

            33%{
              transform: rotate(360deg);
            }

            66%{
              transform: rotate(60deg);
            }

            100%{
              transform: rotate(360deg);
            }
          }
      }

        svg{
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
          width: 35px;
          height: 35px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          animation: scale 1.5s;

          @keyframes scale {
            0%{
              transform: translate(-50%, -50%) scale(1);
            }

            50%{
              transform: translate(-50%, -50%) scale(1.2);
            }

            100%{
              transform: translate(-50%, -50%) scale(1);
            }
          }
        }


      }

      .success-text{
        color: var(--color-main-normal);
        font-size: 1.2rem;
        font-weight: 700;
        margin-top: 1.4rem;
      }

      .redirect-status{
        font-size: 1.05rem;
        color: var(--white);
        opacity: .8;
        margin: 1.1rem 0;
      }

      .countdown{
        color: var(--color-main-dark);
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}