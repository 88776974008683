body{
  &[data-mode="light"] {
      .item-status{
        &.active{
          background: var(--color-active-light);
          border: 2px solid var(--color-active-light);
        }
    }
  }
}

.space-image{
  position: relative;
  margin-right: .3rem;



  .user-image{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    display: inline-block;
    vertical-align: middle;
    margin-right: .3rem;

    & + .item-status{
      right: 6px;
    }
  }

  .item-status{
    position: absolute;
    top: 0;
    right:16px;

  }
}

.item-status{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;


  &.active{
    background: var(--color-active);
    border: 2px solid var(--color-active);
  }

  &.inactive{
    background: #f3f3f3;
    border: 2px solid #f3f3f3;
  }
}
