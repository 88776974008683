$buttonBorder: rgba(255, 255, 255, .75);
$borderlineRed: rgba(255, 0, 0, .75);
$borderlineWhite: rgba(255, 255, 255, .8);
.taskboard-main{
  width: calc(100% - 3.5rem);
  margin-top: 2rem;

  #dropIndicator{
    display: flex;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 10px;

    .indicator-arrow{
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid rgba(255, 255, 255, .5);
    }

    .indicator-line{
      flex-grow: 100;
      height: 2px;
      background: rgba(255, 255, 255, .6);
      transition: all .5s;
      //animation: glow .3s linear infinite;
    }

    @keyframes glow{
      from{
        background: rgba(255, 255, 255, .05);
      }

      to{
        background: rgba(255, 255, 255, .6);
      }
    }
  }

  .project-title{
    font-size: 1.3rem;
    text-align: center;
    line-height: 2.5rem;
    font-weight: 700;
    color: var(--white);
  }
  .structured-data{
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    position: relative;

    @media screen and (min-width: 1200px) {
      .task-list{
        width: calc(25% - 2rem);
      }
    }

    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }

    @media screen and (min-width: 750px) and (max-width: 1200px) {
     .task-list{
       width: calc(50% - 2rem)
     }
    }


    @media screen and (max-width: 749px) {
      .task-list{
        width: 100%;
      }
    }

    .task-list{
      position: relative;

      /*.data-list{
        width: 100%;
        min-height: 100vh;
      }*/


      .task-header{
        line-height: 2rem;
        margin-bottom: 1.5rem;
        .title{
          color: rgba(255, 255, 255, .65);
          font-size: .9rem;
        }

        .icon{
          width: 22px;
          height: 22px;
          color: var(--color-main-normal);
          transition: ease-in-out .2s;

          &:hover{
            cursor: pointer;
            transform: scale(1.1);
          }
        }
      }
      .board-item{
        width: 100%;
        border-radius: 16px;
        background: rgba(0, 0, 0, .35);
        border: 1px solid rgba(2, 165, 206, .09);
        padding: 1.8rem .75rem;
        margin-bottom: 1.2rem;
        position: relative;
        transition: ease-in-out .3s;

        &:hover{
          cursor: pointer;
          border: 1px solid var(--color-main-light);
        }

        &.transition-item{
          animation: transitionItem .8s;
        }

        @keyframes transitionItem {
          from{
            opacity: 0;
            transform: translateY(20px);
          }

          to {
            opacity: 1;
            transform: translateY(0px);
          }
        }
        @-webkit-keyframes transitionItem {
          from{
            opacity: 0;
            transform: translateY(20px);
          }

          to {
            opacity: 1;
            transform: translateY(0px);
          }
        }

        @-moz-keyframes transitionItem {
          from{
            opacity: 0;
            transform: translateY(20px);
          }

          to {
            opacity: 1;
            transform: translateY(0px);
          }
        }
        @-o-keyframes transitionItem {
          from{
            opacity: 0;
            transform: translateY(20px);
          }

          to {
            opacity: 1;
            transform: translateY(0px);
          }
        }


        .board-overlay{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: inherit;
          opacity: .2;
          background: rgba(255, 255, 255, .8);
        }

        .task-id{
          font-size: .6rem;
          font-weight: 500;
          margin-bottom: .5rem;
          color: var(--color-main-normal);
          opacity: .8;
          text-transform: uppercase;
        }

        .task-title{
          font-size: .85rem;
          margin-bottom: 1rem;
          color: var(--color-main-dark);
          font-weight: 700;
        }

        .task-description{
          font-size: .75rem;
          line-height: 1.4rem;
          color: rgba(255, 255, 255, .65);
        }

        .due-date{
          color: rgba(255, 255, 255, .78);
        }
      }
    }
  }
}

.assigned-to{
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.2rem;

  .user-item:not(:last-child){
    margin-right: 10px;
  }

  .user-item{
    padding: .2rem .4rem;
    border-radius: 12px;
    background: rgba(7, 89, 155, .03);
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .user-image{
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: rgba(0, 55, 99, .75);
      color: var(--white);
      font-size: .6rem;
      margin-right: 8px;
    }

    .user{
      font-size: .65rem;
      color: var(--color-main-dark);
    }

    .remove-assignee{
      margin-left: 18px;
      width: 14px;
      height: 14px;
      color: var(--white);
      transition: ease-in-out .2s;

      &:hover{
        transform: scale(1.2);
        cursor: pointer;
      }
    }
  }
}

/*.circle{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 0, 0, .12);
}*/

.add-board-modal{
  @media screen and (min-width: 990px) {
    .modal-content{
      width: calc(45% - 3.5rem);
    }
  }

  @media screen and (min-width: 651px) and (max-width: 989px) {
    .modal-content{
      width: calc(90% - 3.5rem);
    }
  }

  @media screen and (max-width: 650px) {
    .modal-content{
      width: calc(100% - 2rem);
    }
  }

  .modal-header{
    display: flex;
    @media screen and (min-width: 651px) {
      align-items: center;
      justify-content: space-between;
    }
    @media screen and (max-width: 650px) {
      flex-direction: column;

      header{
        margin-bottom: 1rem;
      }
    }
    margin: 1.2rem 0;

    header{
      color: $borderlineWhite;
      font-size: 1.1rem;
      font-weight: 500;
    }

    .task-status{
      font-size: .8rem;

      span{
        margin-right: .6rem;
      }
    }
  }

  .form-item{
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .input-control{
     @media screen and (min-width: 651px) {
       width: calc(50% - 2rem);
     }

      @media screen and (max-width: 650px) {
        width: 100%;
      }
      &:not(:last-child) {
        margin-bottom: 1.75rem;
      }

      label , .label{
        font-size: .85rem;
        line-height: 1.5rem;
      }

      label{
        margin-bottom: .5rem;
        display: block;
      }

      .label{
        padding-bottom: .8rem;
        border-bottom: 1px solid rgba(2, 165, 206, .18);
        .suggestion{
          .input-control{
            width: 100%;
            margin-bottom: 0;
          }
        }

        .new-user{
          .button{
            padding: 6px 18px;
            font-size: .65rem;
            margin-left: .6rem;
            border-radius: 16px;
            width: fit-content;
            width: -moz-fit-content;
          }
        }
      }

      &.full-width{
        width: 100%;
      }

      .no-users{
        width: 100%;
        font-size: .85rem;
      }

      .user-item{
       border: 1px solid rgba(2, 165, 206, .68);
        border-radius: 16px;
      }
    }
  }

  .button-container{
    display: flex;
    border-top: 1px solid rgba(2, 165, 206, .18);
    padding-top: 1rem;
    margin-top: 1.2rem;
    button{
      margin-left: auto;
    }

  }
}


.board-item-modal {
  .modal-content {
    .header-section {
      padding: 1.5rem 1rem;

      @media screen and (min-width: 651px) {
        justify-content: space-between;
        align-items: center;
      }

      @media screen and (max-width: 650px) {
        flex-direction: column;

        .modal-header{
          margin-bottom: 1rem;
        }

        .dropdown-section{
          margin-left: auto;
        }
      }

      .modal-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 50%;
        max-width: 75%;
        .task-id{
          font-size: .65rem;
          color: var(--color-main-light);
          opacity: .5;
          text-transform: uppercase;
        }

        .task-name{
          font-size: 1.05rem;
          margin-top: .4rem;
          font-weight: 600;
          width: 100%;

          .input-control{
            flex-grow: 100;
          }

          button{
            padding: 7px 16px;
            font-size: .75rem;
            margin-left: .5rem;
            border-radius: 16px;
          }
        }
      }

      .dropdown-section{
        .label{
          font-size: .75rem;
          color: var(--color-main-dark);
          margin-right: .6rem;
        }
      }
    }

    .date-container{
      label{
        font-size: .75rem;

      }
      .input-control{
        max-width: 20%;
        margin-left: auto;

        .datepicker-component{
          margin-top: .6rem;
        }
      }
    }

    .item-content{
      padding: 1.75rem 0;
      border-top: 1px solid rgba(2, 165, 206, .18);
      border-bottom: 1px solid rgba(2, 165, 206, .18);

      .task-description{
        padding: 0 1.1rem;
        font-size: .85rem;
        color: rgba(255, 255, 255, .75);
        line-height: 2rem;
        margin-bottom: 1.75rem;

        header{
          font-size: .8rem;
          color: var(--color-main-light);
          opacity: .7;
        }

        .button-container{
          width: fit-content;
          width: -moz-fit-content;
          margin-left: auto;
          button{
            padding: 7px 20px;
            font-size: .75rem;
            border-radius: 16px;
          }
        }
      }

      .assignees{
        width: calc(100% - 2.2rem);
        margin: 0 auto;
        display: flex;

        @media screen and (min-width: 651px) {
          justify-content: space-between;
        }

        @media screen and (max-width: 650px) {
          flex-direction: column-reverse;

          .assign-new-user{
            margin-bottom: 1rem;
          }
        }


        header{
          font-size: .8rem;
          font-weight: 700;
          color: var(--color-main-normal);
        }

        .assigned-users{
          width: calc(65% - 1.5rem);

          .assigned-to{
            .user-item{
              background: var(--black);
              border: 1px solid rgba(0, 139, 160, .3);
              color: var(--white);

            }

            .no-results{
              width: 100%;
              font-size: .8rem;
              text-align: center;
              color: rgba(255, 255, 255, .8);
            }
          }
        }

        .assign-new-user{
          @media screen and (min-width: 651px) {
            width: calc(33% - 1.5rem);
          }

          .user-form{
            margin-top: 1.2rem;

            .dropdown-container{
              width: calc(100% - 3rem);
            }

            .button{
              padding: 6px 18px;
              border-radius: 12px;
              margin-left: 10px;
              background: rgb(0, 55, 99);
              border: 1px solid rgb(0, 55, 99);
              font-size: .65rem;
            }
          }
        }


      }
    }



    .action-buttons{
      padding-top: 1.2rem;

      .delete-section{
        .delete-confirmation{
          transform: translateX(2rem);
          animation: fade .6s;
          transition: ease-in-out .3s;

          span{
            font-size: .75rem;
            color: rgba(255, 255, 255, .85);
            max-width: 200px;
            line-height: 1.5rem;

          }

          .delete-buttons{
            margin-top: .5rem;
            button{
              padding: 7px 16px;
              font-size: .7rem;
              color: $buttonBorder;

              &.button-delete{
                background: $borderlineRed;
                border-top: 1px solid $borderlineRed;
                border-right: 1px solid transparent;
                border-left: 1px solid $borderlineRed;
                border-bottom: 1px solid $borderlineRed;
                border-radius: 16px 0 0 16px;
                color: $buttonBorder;
              }

              &.button-cancel{
                background: transparent;
                border-top: 1px solid $buttonBorder;
                border-right: 1px solid $buttonBorder;
                border-bottom: 1px solid $buttonBorder;
                border-left: 1px solid transparent;
                border-radius: 0 16px 16px 0;
              }
            }
          }

        }

        @keyframes fade {
          from{opacity: 0; transform: translateX(0px)}
          to{opacity: 1; transform: translateX(2rem)}
        }

        @-webkit-keyframes fade {
          from{opacity: 0; transform: translateX(0px)}
          to{opacity: 1; transform: translateX(2rem)}
        }

        @-moz-keyframes fade {
          from{opacity: 0; transform: translateX(0px)}
          to{opacity: 1; transform: translateX(2rem)}
        }

        @-o-keyframes fade {
          from{opacity: 0; transform: translateX(0px)}
          to{opacity: 1; transform: translateX(2rem)}
        }
      }
    }

    .edit-icon{
      width: 15px;
      height: 15px;
      color: var(--color-main-light);
      opacity: .85;
      margin-left: 12px;
      transition: ease-in-out .2s;

      &:hover{
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}



