.spinner {
  width: 16px;
  height: 16px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, .3);
  border-top: 2px solid var(--white);
  animation: spin .3s linear infinite;
  border-radius: 50%;
}

@keyframes spin {
  0%{
    transform: rotate(-30deg);
  }


  100%{
    transform: rotate(360deg);
  }
}

