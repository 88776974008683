.create-new{
  color: var(--white);

  .view-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50% - 2.5rem);
    margin: 0 auto 2rem;

    .project-icon{
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background: rgba(0, 55, 99, .4);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      .icon{
        width: 24px;
        height: 24px;
        opacity: .75;
      }
    }

    header{
      font-size: .96rem;
      text-align: center;
      color: rgba(255, 255, 255, .85);
      font-weight: 600;
    }
  }

  form{
    margin-bottom: 2rem;
    .form-groups{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .input-group{
       @media screen and (min-width: 990px) {
         width: calc(50% - 1.75rem);
       }

        @media screen and (max-width: 989px) {
          width: 100%;
        }
        margin-bottom: 1.5rem;
        position: relative;

        .input-container{
          position: relative;
        }

        .dollar-icon{
          position: absolute;
          width: 16px;
          height: 16px;
          top: 60%;
          left: 8px;
          transform: translateY(-50%);
        }

        &.full-width{
          width: 100%;
        }

        .switch-group{
          margin-top: 20px;

          .switch-label{
            font-size: .8rem;
            &:first-child{
              margin-right: .7rem;
              opacity: .6;
            }

            &:last-child{
              margin-left: .7rem;
              color: var(--color-main-normal);
            }
          }
        }
      }



    }

    .button-container{
      margin-top: 2rem;
      .button{
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
